import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const faqs = [
    {
        question: 'Wie funktioniert es?',
        answer: 'Lorem ipsum ...',
    },
    {
        question: 'Lizenzfreie Bücher',
        answer: 'Lorem ipsum ...',
    },
    {
        question: 'Lizenzpflichtige Bücher',
        answer: 'Lorem ipsum ...',
    },
    {
        question: 'Unsere Motivation',
        answer: 'Lorem ipsum ...',
    },
    {
        question: 'Wo geht die Reise hin?',
        answer: 'Lorem ipsum ...',
    },
    // More questions...
]

export default function FAQS() {
    return (
        <div className="py-20 lg:py-40 bg-gradient-to-r from-blue-50 to-indigo-100" id="faqs">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                    {/* Left Text Block */}
                    <div className="lg:col-span-6 lg:pr-8">
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight">
                            Deine Bücher,
                        </h2>
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 pt-4 lg:pt-8 leading-tight">
                            deine Musik,
                        </h2>
                        <h2 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 pt-4 lg:pt-8 leading-tight">
                            dein Moment.
                        </h2>
                    </div>
                    {/* FAQ Section */}
                    <div className="mt-10 lg:mt-0 lg:col-span-6">
                        <dl className="space-y-6 divide-y divide-gray-300">
                            {faqs.map((faq) => (
                                <Disclosure key={faq.question} as="div" className="pt-6 bg-white rounded-lg shadow-md">
                                    <dt className="p-4">
                                        <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                                            <span className="text-lg font-semibold leading-7 group-hover:text-indigo-600 transition-colors duration-200">
                                                {faq.question}
                                            </span>
                                            <span className="ml-6 flex h-7 items-center">
                                                <PlusSmallIcon aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden transition-transform duration-200" />
                                                <MinusSmallIcon aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden transition-transform duration-200" />
                                            </span>
                                        </DisclosureButton>
                                    </dt>
                                    <DisclosurePanel as="dd" className="p-4 text-gray-600">
                                        <p className="text-base leading-7">{faq.answer}</p>
                                    </DisclosurePanel>
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}

'use client'
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Sidebar from '../components/Sidebar';
import BookList from '../components/BookList';
import { toast } from 'react-toastify';

export default function BookSelection({tags, selectedTags, fetchBooks, setSelectedTags, books, hasMore, fetchMoreBooks, readingList, addToReadingList, removeFromReadingList, fetchReadingList, fetchTags, setPage, api_url, setBooks, page, setHasMore, selectedLanguage, setSelectedLanguage}) {

  useEffect(() => {
    fetchReadingList();
    fetchTags();
  }, []);

  useEffect(() => {
    setPage(1); // Reset page number when filters change
    fetchBooks(true); // Fetch books with reset
  }, [selectedTags, selectedLanguage]);




  return (
    <div className="w-full h-full">
      <Sidebar tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} fetchBooks={setBooks} page={page} setPage={setPage} setHasMore={setHasMore} setSelectedLanguage={setSelectedLanguage} selectedLanguage={selectedLanguage} className="z-50" />
      <div className="flex-1 p-6 mt-8 bg-gray-50">
        {books && books.length > 0 ? (
          <BookList
            books={books}
            hasMore={hasMore}
            fetchMoreBooks={fetchMoreBooks}
            readingList={readingList}
            addToReadingList={addToReadingList}
            removeFromReadingList={removeFromReadingList}
            listType = {"discover"}
            setBook = {null}
            api_url={api_url}
            fetchBooksByStatus={null}
            
          />
        ) : (
          <p className="text-center text-gray-600">No books available</p>
        )}
      </div>
    </div>
  );
}

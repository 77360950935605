export default function Footer() {
    return (
      <footer className="bg-gray-900 text-white py-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Company Info */}
            <div>
              <h3 className="text-lg font-semibold">SymphonyPages</h3>
              <p className="mt-4 text-sm text-gray-400">
                Tauche tiefer in deine Lieblingsbücher ein - mit personalisierter Musik, die deinen Lesemoment unterstützt.
              </p>
            </div>
            {/* Navigation Links */}
            <div>
              <h3 className="text-lg font-semibold">Navigation</h3>
              <ul className="mt-4 space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white">Home</a></li>
                <li><a href="#subscription" className="text-gray-400 hover:text-white">Subscription</a></li>
                <li><a href="#faqs" className="text-gray-400 hover:text-white">FAQs</a></li>
                <li><a href="#contact" className="text-gray-400 hover:text-white">Contact</a></li>
              </ul>
            </div>
            {/* Social Media Links */}
            <div>
              <h3 className="text-lg font-semibold">Follow Us</h3>
              <div className="mt-4 flex space-x-6">
                <a href="#" className="text-gray-400 hover:text-white">
                  <span className="sr-only">Facebook</span>
                  {/* Replace with actual social media icons */}
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M22.675 0h-21.35c-.735 0-1.325.59-1.325 1.325v21.351c0 .735.59 1.325 1.325 1.325h11.494v-9.294h-3.127v-3.631h3.127v-2.669c0-3.1 1.892-4.788 4.654-4.788 1.325 0 2.462.099 2.795.143v3.24l-1.918.001c-1.504 0-1.796.715-1.796 1.763v2.311h3.592l-.467 3.631h-3.125v9.294h6.127c.735 0 1.325-.59 1.325-1.325v-21.35c0-.735-.59-1.325-1.325-1.325z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <span className="sr-only">Twitter</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.563-2.005.973-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-2.719 0-4.924 2.206-4.924 4.925 0 .386.043.761.127 1.122-4.092-.205-7.719-2.165-10.148-5.144-.424.729-.666 1.577-.666 2.476 0 1.71.87 3.217 2.188 4.101-.807-.025-1.566-.247-2.228-.616v.061c0 2.386 1.697 4.374 3.946 4.827-.413.112-.849.172-1.296.172-.317 0-.626-.031-.928-.088.627 1.956 2.445 3.381 4.6 3.423-1.685 1.319-3.808 2.106-6.115 2.106-.398 0-.79-.023-1.175-.068 2.179 1.396 4.768 2.209 7.548 2.209 9.056 0 14.01-7.5 14.01-14.008 0-.213-.004-.425-.014-.637.961-.695 1.797-1.562 2.457-2.549z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <span className="sr-only">Instagram</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 1.17.057 1.953.246 2.392.413.46.177.788.388 1.134.734.347.346.558.673.734 1.134.167.439.356 1.222.413 2.392.058 1.265.07 1.645.07 4.85s-.012 3.584-.07 4.85c-.057 1.17-.246 1.953-.413 2.392-.177.46-.388.788-.734 1.134-.346.347-.673.558-1.134.734-.439.167-1.222.356-2.392.413-1.265.058-1.645.07-4.85.07s-3.584-.012-4.85-.07c-1.17-.057-1.953-.246-2.392-.413-.46-.177-.788-.388-1.134-.734-.347-.346-.558-.673-.734-1.134-.167-.439-.356-1.222-.413-2.392-.058-1.265-.07-1.645-.07-4.85s.012-3.584.07-4.85c.057-1.17.246-1.953.413-2.392.177-.46.388-.788.734-1.134.346-.347.673-.558 1.134-.734.439-.167 1.222-.356 2.392-.413 1.265-.058 1.645-.07 4.85-.07m0-2.163c-3.259 0-3.667.014-4.947.072-1.276.057-2.151.25-2.913.507-.79.265-1.45.623-2.116 1.288-.666.666-1.023 1.326-1.288 2.116-.257.762-.45 1.637-.507 2.913-.058 1.281-.072 1.689-.072 4.947s.014 3.667.072 4.947c.057 1.276.25 2.151.507 2.913.265.79.623 1.45 1.288 2.116.666.666 1.326 1.023 2.116 1.288.762.257 1.637.45 2.913.507 1.281.058 1.689.072 4.947.072s3.667-.014 4.947-.072c1.276-.057 2.151-.25 2.913-.507.79-.265 1.45-.623 2.116-1.288.666-.666 1.023-1.326 1.288-2.116.257-.762.45-1.637.507-2.913.058-1.281.072-1.689.072-4.947s-.014-3.667-.072-4.947c-.057-1.276-.25-2.151-.507-2.913-.265-.79-.623-1.45-1.288-2.116-.666-.666-1.326-1.023-2.116-1.288-.762-.257-1.637-.45-2.913-.507-1.281-.058-1.689-.072-4.947-.072z" />
                    <path d="M12 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.162 6.162 6.162 6.162-2.759 6.162-6.162-2.759-6.162-6.162-6.162zm0 10.138c-2.188 0-3.976-1.788-3.976-3.976s1.788-3.976 3.976-3.976 3.976 1.788 3.976 3.976-1.788 3.976-3.976 3.976zm6.406-11.845c-.796 0-1.44.644-1.44 1.44 0 .796.644 1.44 1.44 1.44.796 0 1.44-.644 1.44-1.44 0-.796-.644-1.44-1.44-1.44z" />
                  </svg>
                </a>
                <a href="#" className="text-gray-400 hover:text-white">
                  <span className="sr-only">LinkedIn</span>
                  <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M22.23 0h-20.46c-.977 0-1.77.792-1.77 1.77v20.46c0 .978.793 1.77 1.77 1.77h20.46c.977 0 1.77-.792 1.77-1.77v-20.46c0-.978-.793-1.77-1.77-1.77zm-14.539 20.454h-3.614v-11.308h3.614v11.308zm-1.807-12.854h-.025c-1.213 0-2.001-.834-2.001-1.876 0-1.063.809-1.875 2.047-1.875 1.239 0 2.001.812 2.026 1.875 0 1.042-.787 1.876-2.047 1.876zm14.1 12.854h-3.609v-5.505c0-1.388-.496-2.337-1.735-2.337-.948 0-1.511.636-1.759 1.252-.091.223-.114.535-.114.849v5.741h-3.608s.047-9.322 0-10.285h3.608v1.458c-.007.012-.018.026-.025.039h.025v-.039c.479-.738 1.338-1.793 3.258-1.793 2.379 0 4.168 1.552 4.168 4.884v6.736z" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-700 pt-8 text-sm text-gray-400 text-center md:flex md:items-center md:justify-between">
            <p>&copy; 2024 SymphonyPages. All rights reserved.</p>
            <div className="mt-4 md:mt-0">
              <a href="#" className="text-gray-400 hover:text-white">Privacy Policy</a> | 
              <a href="#" className="ml-4 text-gray-400 hover:text-white">Terms of Service</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
  
import React, { createContext, useState, useEffect } from 'react';

// Create the Auth context
export const AuthContext = createContext();

// AuthProvider component
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthToken] = useState(null);
  const [loading, setLoading] = useState(true); // Add a loading state

  useEffect(() => {
    // Check for a token in local storage when the app starts
    const token = localStorage.getItem('authToken');
    if (token) {
      setAuthToken(token);
    }
    setLoading(false); // Set loading to false after checking the token
  }, []);

  const login = (token) => {
    // Save the token to local storage and update state
    localStorage.setItem('authToken', token);
    setAuthToken(token);
  };

  const logout = () => {
    // Remove the token and update state
    localStorage.removeItem('authToken');
    setAuthToken(null);
  };

  const isAuthenticated = () => {
    // Check if token is set
    return !!authToken;
  };

  return (
    <AuthContext.Provider value={{ authToken, login, logout, isAuthenticated, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

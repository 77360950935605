import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BookmarkIcon, CheckBadgeIcon, EyeIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import apiClient from '../apiClient';

export default function BookCard({ book, readingList, addToReadingList, removeFromReadingList, listType, setBook, api_url, fetchBooksByStatus }) {

  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(""); // Default to Profile 1
  const [readingListObj, setReadingListObj] = useState(null)


  const toggleOverlay = () => {
    setOverlayVisible(!isOverlayVisible);
  };

  useEffect(() => {
    // Set selectedProfile based on readingList.profile on mount
    if (book.selected_profile) {
          setSelectedProfile(book.selected_profile)
      } else {
        setSelectedProfile("")
      }
    
  }, [book]);

  const handleProfileChange = async (event) => {
    // Update the state first to ensure the selected profile is the latest value
    console.log("here")
    const newProfile = event.target.value;
    setSelectedProfile(newProfile);
  
    // Then, make the API request with the updated profile
    try {
      await apiClient.patch(`${api_url}/api/books/${book.id}/`, {
        selected_profile: newProfile
      });
      console.log("Profile updated successfully");
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const finishBook = async (bookId) => {
    try {
      console.log("Marking book as finished:", bookId);
  
      const response = await apiClient.post(`${api_url}/api/reading-list/finish-reading/`, 
      {
        book_id: bookId,  // Send book_id in the request body
      });
  
      console.log("Book finished successfully:", response.data);
      fetchBooksByStatus("reading");
    } catch (error) {
      console.error('Error finishing the book:', error);
    }
  };
  
  return (
    <li className="relative flex flex-col border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-md transition-shadow duration-300 overflow-hidden">

      {!isOverlayVisible ? (
      <div className="flex flex-1 flex-col p-4">
        
        {listType === "discover" ? (
          <Link to={`/reader/book-detail/${book.id}`} className="flex justify-center">
            <img
              alt={book.title}
              src={book.cover}
              className="h-64 md:h-72 flex-shrink-0 rounded-md object-cover cursor-pointer transition-transform transform hover:scale-105"
              style={{ aspectRatio: '2 / 3' }}
            />
          </Link>
        ) : (
          <div className="flex justify-center">
            <img
              alt={book.title}
              src={book.cover}
              className="h-64 md:h-72 flex-shrink-0 rounded-md object-cover cursor-pointer transition-transform transform hover:scale-105"
              style={{ aspectRatio: '2 / 3' }}
              onClick={() => setBook(book)}
            />
          </div>
        )}
        <div className="mt-4">
          <h3 className="text-base font-semibold text-gray-900 leading-tight">{book.title}</h3>
          <dl className="mt-1 text-sm text-gray-500">
            <div>
              <dt className="sr-only">Author</dt>
              <dd>{book.author}</dd>
            </div>
            <div className="mt-1">
              <dt className="sr-only">Year</dt>
              <dd>{book.year}</dd>
            </div>
          </dl>

          {/* Conditional Dropdown with Eye Icon */}
          {listType === "toread" && (
            <div className="mt-2 flex items-center space-x-2 relative">
              <select className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700"
                onChange={handleProfileChange}
                value={selectedProfile}>
                <option value="profile_1">Profile 1</option>
                <option value="profile_2">Profile 2</option>
                <option value="profile_3">Profile 3</option>
                {/* Add more options as needed */}
              </select>
              <EyeIcon
                className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-700 transition-colors"
                onClick={toggleOverlay}
              />
            </div>
          )}

          <div className="mt-3 flex flex-wrap">
            {book.genre_tags.map((tag) => (
              <span key={tag.id} className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full mr-1 mb-1">
                {tag.name}
              </span>
            ))}
          </div>
        </div>
      </div>

      ):(
        <>
        <div className="flex flex-1 flex-col p-4">
          <p className='h-64 md:h-72 overflow-y-auto'>
            {selectedProfile === "profile_1" && book.profile_one}
            {selectedProfile === "profile_2" && book.profile_two}
            {selectedProfile === "profile_3" && book.profile_three}
          </p>
          <div className="mt-4">
          <h3 className="text-base font-semibold text-gray-900 leading-tight">{book.title}</h3>
          <dl className="mt-1 text-sm text-gray-500">
            <div>
              <dt className="sr-only">Author</dt>
              <dd>{book.author}</dd>
            </div>
            <div className="mt-1">
              <dt className="sr-only">Year</dt>
              <dd>{book.year}</dd>
            </div>
          </dl>
              <div className="mt-2 flex items-center space-x-2 relative">
                <select 
                className="w-full border border-gray-300 rounded-md p-2 text-sm text-gray-700"
                onChange={handleProfileChange}
                value={selectedProfile}>
                  <option value="profile_1">Profile 1</option>
                  <option value="profile_2">Profile 2</option>
                  <option value="profile_3">Profile 3</option>
                  {/* Add more options as needed */}
                </select>
                <EyeIcon
                  className={`h-6 w-6 cursor-pointer hover:text-gray-700 transition-colors ${isOverlayVisible ? 'text-indigo-600': 'text-gray-500'}`}
                  onClick={toggleOverlay}
                />
              </div>
          </div>
          <div className="mt-3 flex flex-wrap">
            {book.genre_tags.map((tag) => (
              <span key={tag.id} className="inline-block bg-blue-100 text-blue-800 text-xs font-medium px-2 py-1 rounded-full mr-1 mb-1">
                {tag.name}
              </span>
            ))}
          </div>
        </div>
        
        </>
      )}
      
      {listType === "discover" ? (
        <div className="flex">
          {readingList.includes(book.id) ? (
            <BookmarkIcon
              className="h-6 w-6 absolute top-2 right-2 cursor-pointer text-blue-500 hover:text-blue-700 transition-colors"
              aria-hidden="true"
              onClick={() => removeFromReadingList(book.id)}
            />
          ) : (
            <BookmarkIcon
              className="h-6 w-6 absolute top-2 right-2 cursor-pointer text-gray-300 hover:text-gray-500 transition-colors"
              aria-hidden="true"
              onClick={() => addToReadingList(book.id)}
            />
          )}
        </div>
      ) : null}


      {listType === "reading" && (
        <CheckBadgeIcon
          className="h-10 w-10 absolute top-2 right-2 cursor-pointer text-blue-300 hover:text-blue-500 transition-colors"
          aria-hidden="true"
          onClick={() => finishBook(book.id)}
        />
      )}
    </li>
  );
}

'use client'
import React, { useState, useRef } from 'react';
import { FunnelIcon, XMarkIcon } from '@heroicons/react/24/solid';

const Sidebar = ({ tags, selectedTags, setSelectedTags, setSelectedLanguage, selectedLanguage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollContainerRef = useRef(null);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  // Handle tag selection
  const handleTagChange = (tag) => {
    const newSelectedTags = selectedTags.includes(tag)
      ? selectedTags.filter((t) => t !== tag)
      : [...selectedTags, tag];
    setSelectedTags(newSelectedTags);
  };

  // Handle language selection
  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setSelectedLanguage(newLanguage);
  };
  
  return (
    <div className="relative w-full h-full">
      {/* Toggle Sidebar Button */}
      <button
        className={`fixed top-16 right-4 z-50 p-3 rounded-full bg-blue-600 text-white shadow-lg transition-transform duration-500 transform rotate-0`}
        onClick={toggleSidebar}
      >
        {isOpen ? (
          <XMarkIcon className="w-6 h-6 transform rotate-0" />
        ) : (
          <FunnelIcon className="w-6 h-6 transform rotate-45" />
        )}
      </button>
      {/* Horizontal Sliding Vertical Sidebar */}
      <div
        className={`fixed top-16 right-0 bg-white shadow-lg h-auto max-h-[70vh] w-64 z-40 px-6 py-4 transform rounded-lg transition-transform duration-500 ease-in-out ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="flex flex-col space-y-4 overflow-y-auto">
          <h2 className="text-lg font-semibold text-gray-700">Filter Tags</h2>
          <div ref={scrollContainerRef} className="flex flex-col space-y-2">
            {tags && tags.map((tag) => (
              <label
                key={tag.id}
                className="flex items-center space-x-2 bg-gray-100 rounded-full px-3 py-1 cursor-pointer hover:bg-gray-200"
              >
                <input
                  type="checkbox"
                  checked={selectedTags.includes(tag.name)}
                  onChange={() => handleTagChange(tag.name)}
                  className="hidden"
                />
                <span className={`text-sm font-medium ${selectedTags.includes(tag.name) ? 'text-blue-600' : 'text-gray-700'}`}>
                  {tag.name}
                </span>
              </label>
            ))}
          </div>

          {/* Language Filter Dropdown */}
          <h2 className="text-lg font-semibold text-gray-700 mt-4">Filter by Language</h2>
          <select
            value={selectedLanguage}
            onChange={handleLanguageChange}
            className="p-2 bg-gray-100 rounded-full border border-gray-300"
          >
            <option value="all">All</option>
            <option value="English">English</option>
            <option value="German">German</option>
            {/* Add more languages as needed */}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

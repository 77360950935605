import axios from 'axios';

// Create an instance of axios
const apiClient = axios.create();

// Set the Authorization header for all requests
apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken');  // Get the access token from local storage
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

// Function to refresh the token
const refreshToken = async () => {
  try {
    const refresh = localStorage.getItem('refreshToken');  // Get the refresh token from local storage
    if (refresh) {
      const response = await axios.post('/token/refresh/', {
        refresh: refresh,
      });
      const newAccessToken = response.data.access;

      // Update the token in local storage and return the new token
      localStorage.setItem('authToken', newAccessToken);
      return newAccessToken;
    }
    return null;
  } catch (error) {
    console.error('Error refreshing token:', error);
    return null;
  }
};

// Intercept responses to handle 401 errors
apiClient.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalRequest = error.config;

  if (error.response && error.response.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;  // Mark the request to prevent infinite loops

    const newToken = await refreshToken();
    if (newToken) {
      // Update the Authorization header with the new token
      originalRequest.headers.Authorization = `Bearer ${newToken}`;
      // Retry the original request with the new token
      return apiClient(originalRequest);
    } else {
      // If the refresh token fails, redirect to login
      window.location.href = '/login';  // Adjust the path to your login page if necessary
    }
  }
  
  return Promise.reject(error);
});

export default apiClient;

import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import BookCard from './BookCard';

export default function BookList({ books, hasMore, fetchMoreBooks, readingList, addToReadingList, removeFromReadingList, listType, setBook, api_url, fetchBooksByStatus }) {
  return (

    <InfiniteScroll
      dataLength={books.length} // Length of the books array
      next={fetchMoreBooks} // Function to fetch more books
      hasMore={hasMore} // Indicates if there are more books to load
      loader={<h4 className="text-center text-blue-500">Loading more books...</h4>} // Loader component
      endMessage={<p className="text-center mt-12 text-gray-600">No more books available</p>} // End message component
    >
      {/* Responsive Books List Container */}
      <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 p-4">
        {books.map((book) => (
          <BookCard
            key={book.id} // Unique key for each book
            api_url={api_url}
            book={book}
            readingList={readingList}
            addToReadingList={addToReadingList}
            removeFromReadingList={removeFromReadingList}
            listType = {listType}
            setBook = {setBook}
            fetchBooksByStatus={fetchBooksByStatus}
          />
        ))}
      </ul>
    </InfiniteScroll>
  );
}
